@import 'variable';
@import 'mixin';

/* ======================================================================
 p_404
====================================================================== */

.p_404 {
  .l_content {
    .l_main {
      margin: 100px auto 0;
      @include mq_max(768) {
        margin: 0;
        padding: 30px 15px 60px;
        width: 100%;
      }
      .article {
        width: 900px;
        margin: 0 auto;
        color: #fff;
        @include mq_max(768) {
          width: 100%;
          padding: 0 15px;
        }
        * {
          font-size: 16px;
          @include mq_max(768) {
            font-size: 14px;
          }
        }
        p {
          line-height: 1.8;
          >a {
            color: #C8AF42;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
              @include mq_max(768) {
                text-decoration: underline;
              }
            }
          }
        }
        ul {
          margin: 30px 0;
          padding: 0 0 0 40px;
          @include mq_max(768) {
            padding: 0 0 0 20px;
          }
          li {
            padding: 0 0 0 23px;
            position: relative;
            @include mq_max(768) {
              line-height: 1.6;
            }
            &:not(:first-child) {
              margin: 10px 0 0;
            }
            &:before {
              position: absolute;
              top: 4px;
              left: 0;
              content: '';
              height: 12px;
              width: 12px;
              border: 1px solid #C8AF42;
              transform: rotate(45deg);
              @include mq_max(768) {
                top: 7px;
              }
            }
            &:after {
              content: '';
              background: #C8AF42;
              position: absolute;
              height: 6px;
              width: 6px;
              top: 7px;
              left: 3px;
              transform: rotate(45deg);
              @include mq_max(768) {
                top: 10px;
              }
            }
          }
        }
      }
      .btn-wrap {
        margin: 100px 0 0;
        @include mq_max(768) {
          width: 100%;
          margin: 60px 0 0;
        }
        .c_btn_3 {
          margin: 30px 0 0;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          justify-content: center;
          @include mq_max(768) {
            margin: 0;
          }
          .btn {
            width: 290px;
            height: 60px;
            font-size: 16px;
            border-radius: 3px;
            border: 1px solid #fff;
            background: rgba(255,255,255,.15);
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            @include mq_max(768) {
              width: 100%;
            }
            &:hover {
              &:before {
                width: 73px;
                height: 35px;
                right: 10px;
                background: url(../img/common/btn-img-hover.svg);
                background-size: cover;
                @include mq_max(768) {
                  top: 19px;
                  right: 28px;
                  width: 44px;
                  height: 21px;
                }
              }
            }
            &:before {
              content: '';
              top: 13px;
              right: 20px;
              position: absolute;
              width: 39px;
              height: 32px;
              background: url(../img/common/btn-img.svg);
              background-size: cover;
              @include mq_max(768) {
                top: 19px;
                right: 38px;
                width: 25px;
                height: 21px;
              }
            }
            .btntxt {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
